import { createDynamicForms } from "@asigloo/vue-dynamic-forms"
import CoreuiVue from "@coreui/vue"
import axios from "axios"
import { ClientTable, ServerTable } from "v-tables-3"
import { createApp } from "vue"
import { createMetaManager } from "vue-meta"
import router from "./router"
import CIcon from "@/components/LazyCIcon.vue"
import i18n from "@/plugins/i18n"

async function initVqIdbCache() {
  await import("@/idb")
}

async function init() {
  if (window.hasSW) await navigator.serviceWorker.ready
  else await initVqIdbCache()

  const { default: App } = await import("./App.vue")
  const app = createApp(App)

  const { fillMemCache } = await import("@/libraries/helpers")
  await fillMemCache()

  app.use(router)

  axios.defaults.baseURL = import.meta.env.VITE_APP_BACKEND_URL
  axios.defaults.withCredentials = true

  const { default: pinia } = await import("./pinia")

  app.use(pinia)

  app.use(CoreuiVue)

  app.use(i18n)

  app.component("CIcon", CIcon)

  const table_options = {
    pagination: { chunk: 5, nav: "scroll", edge: false },
    sortIcon: {
      base: "fa fa-lg",
      up: "fa-sort-asc",
      down: "fa-sort-desc",
      is: "fa-sort",
    },
    perPageValues: [10, 20, 30, 40, 50],
    skin: "table table-striped table-hover",
    dateColumns: ["created", "updated"],
    texts: {
      count: "Showing {from} to {to}",
      filter: "",
      filterPlaceholder: "Filter username",
      limit: "",
      page: "Page:",
      noResults: "No results..",
      loading: "Loading..",
    },
  }

  app.use(ServerTable, {
    options: table_options,
  })

  app.use(ClientTable, {
    options: table_options,
  })

  const VueDynamicForms = createDynamicForms()

  app.use(VueDynamicForms)

  app.use(createMetaManager())
  
  // This is here to smoothen the transition a bit
  // for when the app is ready to mount.
  const wrapper = document.getElementById("wrapper")

  if (wrapper?.classList.contains("visible")) {
    wrapper.classList.remove("visible")
    wrapper.ontransitionend = () => app.mount("#app")
  } else app.mount("#app")
}

init()
