import { createRouter, createWebHistory } from "vue-router"
import { authStore, requestStore } from "@/store"
import routes from "./routes"
import { isErrorPage, isProtectedPage } from "./utils"
import { getNickname } from "@/libraries/helpers"

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0 }),
  strict: true,
})

router.beforeEach(async route => {
  requestStore.removeRunningFetch()
  
  if (!route.matched.length) return "/404"
  if (isErrorPage(route)) return
  if (isProtectedPage(route))
    return (
      authStore.isAuthenticated ||
      `/login?returnTo=${route.fullPath}`
    )

  if (authStore.isAuthenticated) return "/"
  const nickname = getNickname(route).value
  if (!nickname) return
  localStorage.setItem("manufacturer", nickname)
})

export default router
export * from "./utils"
