/// <reference no-default-lib="true"/>
/// <reference lib="esnext" />
/// <reference lib="dom" />

import type { _RouteLocationBase as Route } from "vue-router"
import { LOGIN_URL } from "@/constants"

export const guestPages = [
  "/",
  "/login",
  "/reset",
  "/forgot",
  "/register",
  "/verify",
  "/terms",
] as const

const path = (route: Route | Location | URL) =>
  "path" in route ? route.path : route.pathname

export const isErrorPage = (route: Route | Location | URL = location) =>
  /^\/\d{3}$/.test(path(route))

export const isGuestPage = (route: Route | Location | URL = location) =>
  guestPages.some(guestPath => path(route).endsWith(guestPath))

export const isProtectedPage = (route: Route | Location | URL = location) =>
  !isGuestPage(route) && !isErrorPage(route)

export const isAdminPage = (route: Route | Location | URL = location) =>
  path(route).startsWith("/admin")

export const isLoginUrl = (url: string) => url === LOGIN_URL
