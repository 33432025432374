import type { RouteRecordRaw } from "vue-router"
import { isErrorPage, isGuestPage, isProtectedPage } from "./utils"
import { authStore } from "@/store"
import { featureConfigs } from "@/configs"

const routerView = {
  template: "<router-view />",
}

let pricingRoute = null as RouteRecordRaw
if (featureConfigs.pricings?.enabled) {
  pricingRoute = {
    path: "pricings",
    name: "pricings",
    component: () => import("@/views/pricings/Pricings.vue"),
  }
}

const routes: Array<RouteRecordRaw> = [
  /** Authenticated Pages */
  {
    path: "/",
    name: "home",
    component: () => (!isErrorPage() || authStore.isAuthenticated ? import("@/layouts/DefaultLayout.vue") : routerView),
    redirect: () => {
      if (isErrorPage()) return { name: "errors" }
      if (!authStore.isAuthenticated) return { name: "guest" }
      if (isGuestPage()) return "/dashboard"

      const redirect = location.pathname + location.search
      return redirect === "/" ? "/dashboard" : redirect
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "stock",
        redirect: "/stock/sheets",
        name: "materials",
        component: routerView,
        children: [
          {
            path: "sheets",
            name: "plates",
            component: () => import("@/views/materials/Sheets.vue"),
          },
          {
            path: "profiles",
            name: "profiles",
            component: () => import("@/views/materials/Profiles.vue"),
          },
          {
            path: "raw",
            name: "raw",
            component: () => import("@/views/materials/Raw.vue"),
          },
        ],
      },
      {
        path: "orders",
        redirect: "/orders/all",
        name: "orders",
        component: routerView,
        children: [
          {
            path: "all",
            name: "All Orders",
            component: () => import("@/views/orders/Orders.vue"),
          },
          {
            path: "new",
            name: "start_order",
            component: () => import("@/views/orders/New.vue"),
          },
          {
            path: ":id/edit",
            name: "edit_order",
            component: () => import("@/views/orders/New.vue"),
          },
          {
            path: ":id",
            name: "order_detail",
            component: () => import("@/views/orders/Detail.vue"),
          },
        ],
      },
      {
        path: "nestings",
        redirect: "/nestings/queue",
        name: "nestings",
        component: routerView,
        children: [
          {
            path: "new",
            name: "Order Nesting",
            component: () => import("@/views/nestings/New.vue"),
          },
          {
            path: "queue",
            name: "Nesting Queue",
            component: () => import("@/views/nestings/Nestings.vue"),
          },
          {
            path: "plans",
            name: "Nesting Plans",
            component: () => import("@/views/nestings/Plans.vue"),
          },
        ],
      },
      // Users page
      {
        path: "users",
        name: "users",
        component: () => import("@/views/users/Users.vue"),
      },
      // Customers page
      {
        path: "customers",
        name: "customers",
        component: () => import("@/views/customers/Customers.vue"),
      },
      // Machines page
      {
        path: "machines",
        name: "machines",
        component: () => import("@/views/machines/Machines.vue"),
      },
      // Tools page
      {
        path: "tools",
        name: "tools",
        component: () => import("@/views/tools/Tools.vue"),
      },
      // Admins Page
      {
        path: "admin",
        name: "admin",
        redirect: "/admin/clients",
        component: routerView,
        children: [
          {
            path: "organizations",
            name: "Organizations",
            component: () => import("@/views/admins/Organizations.vue"),
          },
          {
            path: "manufacturers",
            name: "Manufacturers",
            component: () => import("@/views/admins/Manufacturers.vue"),
          },
          {
            path: "clients",
            name: "Clients",
            component: () => import("@/views/admins/Clients.vue"),
          },
          {
            path: "tokens",
            name: "Tokens",
            component: () => import("@/views/admins/Tokens.vue"),
          },
          {
            path: "logs",
            name: "Logs",
            component: () => import("@/views/admins/Logs.vue"),
          },
        ],
      },
      {
        path: "files",
        name: "files",
        component: () => import("@/views/files/Files.vue"),
      },
      // Webhooks page
      {
        path: "webhooks",
        name: "webhooks",
        component: () => import("@/views/webhooks/Webhooks.vue"),
      },
      pricingRoute,
    ].filter(v => v !== null) as RouteRecordRaw[],
  },
  /** End Authenticated Pages */

  /** Guest Pages */
  {
    path: "/",
    name: "guest",
    component: routerView,
    redirect: () => {
      if (isErrorPage()) return { name: "errors" }
      if (authStore.isAuthenticated) return { name: "home" }
      if (isProtectedPage()) return "/login"

      const redirect = location.pathname + location.search
      return redirect === "/" ? "/login" : redirect
    },
    children: <RouteRecordRaw[]>[
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/:nickname/login",
        name: "login_with_nickname",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/forgot",
        name: "forgot",
        component: () => import("@/views/auth/Forgot.vue"),
      },
      {
        path: "/:nickname/forgot",
        name: "forgot_with_nickname",
        component: () => import("@/views/auth/Forgot.vue"),
      },
      {
        path: "/reset",
        name: "reset",
        component: () => import("@/views/auth/Reset.vue"),
      },
      {
        path: "/:nickname/reset",
        name: "reset_with_nickname",
        component: () => import("@/views/auth/Reset.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("@/views/auth/Register.vue"),
      },
      {
        path: "/:nickname/register",
        name: "register_with_nickname",
        component: () => import("@/views/auth/Register.vue"),
      },
      {
        path: "/verify",
        name: "verify",
        component: () => import("@/views/auth/Verify.vue"),
      },
      {
        path: "/:nickname/verify",
        name: "verify_with_nickname",
        component: () => import("@/views/auth/Verify.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/errors/Terms.vue"),
      },
      {
        path: "/:nickname/terms",
        name: "terms_with_nickname",
        component: () => import("@/views/errors/Terms.vue"),
      },
    ],
  },
  /** End Guest Pages */

  /** Error Pages */
  {
    path: "/",
    name: "errors",
    component: routerView,
    redirect: "/404",
    children: [
      {
        path: "/403",
        name: "Page403",
        component: () => import("@/views/errors/Page403.vue"),
      },
      {
        path: "/404",
        name: "Page404",
        component: () => import("@/views/errors/Page404.vue"),
      },
      {
        path: "/500",
        name: "Page500",
        component: () => import("@/views/errors/Page500.vue"),
      },
    ],
  },
  /** End Error Pages */

  // {
  //   path: '/:pathMatch(.*)',
  //   redirect: '/404'
  // },
]

export default routes
